vs-page-heading {
  background-color: white;
  box-shadow: none;
  border-bottom: 1px solid #e1e1e1;
}

.panel {
  border: 0;
  box-shadow: none;
}

.ellipsified {
  overflow: hidden;
  text-overflow: ellipsis;
}

#fetching {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.cj-panel.panel-body div.message-container {
  display: flex;
  justify-content: center;
}

.page-header {
  margin: 0 0 3em;
  padding: 0 0 16px;
  border-bottom: 1px solid #ccc;
}

.page-title {
  margin-top: 0;
}

.docs-img-placeholder {
  display: inline-block;
  width: 256px;
  background: #ddd;
  height: 128px;
  margin: 8px 0;

  line-height: 128px;
  font-size: 18px;
  color: #aaa;
  text-align: center;
  vertical-align: middle;
}

.container.content {
  margin: 0;
}

.input-container {
  margin-bottom: 8px;
}

/* BEGIN TABLE OF CONTENTS STYLING */

ul > div.toc > a {
  cursor: pointer;
  color: #000;
}

.vs-sliding-panel:first-child {
  padding-top: 24px;
}

.toc.top-level-item {
    padding: 8px 20px;
    margin-left: 4px;
}

.toc.top-level-item.active {
    border-left: 4px solid #49c5b1;
    padding: 8px 20px;
    margin-left: 0px;
}

.toc.second-level-item {
  padding: 8px 32px;
}

.toc.second-level-item.active {
  border-left: solid 4px #49c5b1;
  padding: 8px 28px;
}

.toc.top-level-item > a {
  font-weight: 600;
  color: #000;
}

.toc.second-level-item > a {
  color: #000;
}

.graph-ql-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 45px);
}

.scroll-container {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 0vw;
  flex: 1 0 auto;
}

#container > .vs-page-content {
  overflow: initial;
}

/* END TABLE OF CONTENTS STYLING */
