.name-form-field-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.name-form-field-group .form-group {
  flex: 1 1 auto;
  margin-bottom: 5px;
}

.name-form-field-group .form-group > .col-sm-5 {
  width: 100%;
}

.name-form-field-group .form-group > .col-sm-3,
.name-form-field-group .form-group > .col-xs-4 {
  padding: 0;
  width: 0;
}

.name-form-field-group * label {
  display: inline-block;
}

input.form-control.error {
  outline: 1px solid red;
  box-shadow: 0 0 5px 0 red;
}

.application-intro-portal-text {
  font-size: 15px;
  margin: 10px;
  max-width: 1240px;
  flex-grow: 1;
}

.application-intro-portal-text > h2 {
  margin-top: 0;
}

.personal-access-token-card {
  max-width: 460px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 32px;
  padding: 20px 3px;
}

.personal-access-token-card > span {
  font-size: 18px;
  align-items: center;
  margin: 0 15px 15px;
  display: flex;
}

.personal-access-token-card > span > svg {
  margin-right: 16px;
}


.personal-access-token-card .col-sm-5 {
  width: 100%;
}

.personal-access-token-register-group {
  margin-top: 35px;
}

.personal-access-token-form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.personal-access-token-form-group .personal-access-token-form-field {
  flex: 2 1 auto;
  margin-right: 8px;
}

.personal-access-token-form-group .form-label {
  font-weight: 600;
}

.personal-access-token-button {
  margin-left: 15px;
}

.temp-personal-access-token-display {
  display: flex;
  flex-direction: column;
  border-left: solid 5px #00af65;
  border-radius: 3px;
  padding: 10px 20px 15px;
}

.temp-personal-access-token-header > button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 0;
  padding: 0;
  background: #eee;
}

.temp-personal-access-token-header > button > svg {
  width: 23px;
  height: 23px;
}

.temp-personal-access-token-name {
  font-weight: 600;
}

.temp-personal-access-token,
.temp-personal-access-token-name {
  margin-bottom: 20px;
  font-size: 14px;
}

.temp-personal-access-token-warning {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #858585;
}

.temp-personal-access-token-header {
  display: flex;
  justify-content: space-between;
}

.application-portal-wrapper {
  overflow: scroll;
}
