body {
  background-color: #ffffff;
}

aside.notice {
  background-color: #99FFFF;
}

input.form-control {
  display: inline-block;
}

.vs-sidenav .vs-sideNav-left-logo .vs-sidenav-container-row {
  background: #025450;
}

label input.form-control {
  height: 1em;
}

span.error {
  color: #e20000;
  float: right;
}

div.flex {
  display: flex;
}

h2:not(:first-child),
h3:not(:first-child),
h4:not(:first-child),
h5:not(:first-child),
h6:not(:first-child) {
  margin-top: 32px;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
}

h1, .h1 { font-size: 32px;
  line-height: 40px;
  letter-spacing: -.4px;
}

h1:first-of-type {
  margin-top: 0px;
}

h2, .h2 {
  font-size: 26px;
  line-height: 34px;
  letter-spacing: -.4px;
}
h3, .h3 {
  font-size: 20px;
  line-height: 28px;
}
h4, .h4 {
  font-size: 16px;
  line-height: 26px;
}

h5, .h5 {
  font-size: 16px;
  line-height: 24px;
}

h6, .h6 {
  font-size: 16px;
  line-height: 22px;
}
p {
  margin-bottom: 1.5rem;
}

hr {
  margin-top: 2em;
  margin-bottom: 2em;
  border: 0;
  border-top: 1px solid #ccc;
}

ul, ol {
  margin: 0 0 2em;
  padding: 0 0 0 2em;
}

table td.vs-cell {
  padding-top: 8px;
  padding-bottom: 8px;
}

table td p:last-child {
  margin-bottom: 0;
}

p, pre, table {
  margin-bottom: 16px;
}

pre  {
  background-color: white;
  border: 0;
  font-family: "Open Sans", sans-serif;
  padding: 0;
}

table {
  margin-top: 2em;
  margin-bottom: 4em;
}

code, code * {
  font-family: monospace;
}

.vs-page-content {
  padding: 24px;
  background: #f6f6f6;
}

.panel-body {
  padding: 24px;
}
