:local .footer {
  color: #999;
  margin: 20px;
  align-self: center;
}

:local .footer a {
  color: #999;
  text-decoration: underline;
}
