span {
  font-size: inherit;
}

.alert-circle-icon {
  margin: -3px 8px 0px 0px;
  padding: 0px;
  width: 25px;
  height: 25px;
  fill: #d42727;
}

.deprecated-label {
  text-decoration: line-through;
}

.deprecation-reason-box {
  border: 1px solid #d42727;
  border-radius: 3px;
  background-color: #fbeeee;
  padding: 8px;
  margin: 4px 0px 4px 0px;
  display: flex;
  flex-direction: row;
}

.deprecation-reason-label {
  font-weight: bold;
  color: #d42727;
}
