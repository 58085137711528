.login-card {
  background: white;
  margin: 30px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.login-card-stack-left {
  display: flex;
}

.login-card:hover {
  text-decoration: none;
}

.login-text {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 20px;
}

.login-subtext {
  color: #858585;
}

.login-notification {
  font-size: 16px;
  margin-bottom: 5px;
}

.lock-icon {
  margin-left: 25px;
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.arrow-icon {
  margin-right: 25px;
  display: flex;
  color: #858585;
  justify-content: center;
  width: 25px;
  height: 25px;
}
