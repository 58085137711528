.error-page {
  display: flex;
  justify-content: center;
}

.error-page-card {
  width: 500px;
  min-height: 125px;
  margin: 80px;
  padding: 20px;
}

.error-title {
  display: flex;
  align-items: center;
}

.error-text {
  font-size: 15px;
  margin: 10px 20px;
}

.error-subtext {
  color: #858585;
  margin: 0px 42px;
}

.error-home-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.error-chevron-icon {
  height: 15px;
  width: 15px;
  padding: 0px;
}
