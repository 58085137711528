div.graphiQLWrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

div.graphiQLWrapper * {
  box-sizing: content-box;
}

/* custom design components for Graphiql:
   https://github.com/graphql/graphiql/blob/main/packages/graphiql-react/src/style/root.css
 */
.graphiql-container,
.CodeMirror-info,
.CodeMirror-lint-tooltip,
reach-portal {
  /* Font */
  --font-size-hint: calc(12rem / 10);
  --font-size-inline-code: calc(13rem / 10);
  --font-size-body: calc(13rem / 10);
  --font-size-h4: calc(18rem / 10);
  --font-size-h3: calc(22rem / 10);
  --font-size-h2: calc(29rem / 10);
}

.graphiql-container .graphiql-toolbar {
  margin-left: var(--px-4);
}

.graphiql-container .graphiql-query-editor {
  padding: var(--px-4);
}

.graphiql-container .graphiql-sidebar {
  width: unset;
  padding-left: var(--px-4);
  padding-right: var(--px-4);
}
